.contenedorIntroAppBarLogo{
    height: 92vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .speechBubble{
        position: absolute;
        bottom: 25%;
        left: 5%;
        width: 80%;
        background: #4ABEFF;
        border-radius: .4em;
        color: black;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        text-align: left;
        padding: 1rem;
    }
    .speechBubble:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 15%;
        width: 0;
        height: 0;
        border: 33px solid transparent;
        border-top-color: #4ABEFF;
        border-bottom: 0;
        border-right: 0;
        margin-left: -16px;
        margin-bottom: -32px;
    }
    .continueMessage{
        box-sizing: border-box;
        width: 55%;
        border: 1px solid #40A7E0;
        border-radius: 8px;
        position: absolute;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #40A8E0;
        text-align: left;
        bottom: 10%;
        right: 10%;
        padding: 1rem;
    }
    .imgLogo{
        width: 200px;
        height: 45px;
    }
    .bottomAppBar{
        .confirmar{
            color: blue;
        }
    }
}