.contenedorBottom{
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8%;
    background: rgba(0, 0, 0, 0.25);
    justify-content: center;
    .contenedorAccionesExpandido{
        display: flex;
        flex-direction: column;
        .contenedorAccionesExpandidoSuperior{
            display: flex;
            align-items: center;
            .disminuirTexto{
                width: 9px;
                height: 18px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-align: center;
                width: 11px;
                margin: 0 0.5rem 0 2rem;
            }
            .rangoTexto{
                appearance: none;
                -webkit-appearance: none;
                height: 0;
                width: 136.5px;
                border: 1px solid rgba(255, 255, 255, 0.25)
            }
            .rangoTexto::-webkit-slider-thumb{
                appearance: none;
                -webkit-appearance: none;
                height: 15px;
                width: 15px;
                border-radius: 50%;
                background: white;
            }
            .aumentarTexto{       
                width: 11px;     
                height: 22px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                display: flex;
                align-items: center;
                text-align: center;
                margin: 0 2rem 0 0.5rem;
            }
            .descText{
                width: 170px;
                height: 24px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                display: flex;
                align-items: center;
                margin: 0;
                color: rgba(255, 255, 255, 0.25);
            }
        }
        .contenedorAccionesExpandidoInferior{
            display: flex;
            margin-bottom: 2rem;
            .descTexto{
                position: absolute;
                width: 84px;
                height: 11px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 0.35);
                text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
            }
            .descDismi{
                margin-left: 2rem;
            }
            .descAumen{
                margin-left: 8rem;
            }
        }
    }    
    .contenedorBotonAcciones{
        display: flex;
        .botonAcciones{
            width: 85%;
            display: flex;
            justify-content: space-evenly;
            margin-left: 10%;
        }
        .opciones{
            display: flex;
            justify-content: center;
            width: 15%;
        }
        .boton{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border: none;
            font-family: 'Open Sans';
            font-style: normal;
            font-size: 10px;
            font-weight: 600;
            line-height: 14px;
            align-items: center;
            text-align: center;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
            color: rgba(255, 255, 255, 0.35);
            background-color: transparent;
        }
        .confirmar{
            color: #4ABEFF;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border: none;
            font-family: 'Open Sans';
            font-style: normal;
            font-size: 10px;
            font-weight: 600;
            line-height: 14px;
            align-items: center;
            text-align: center;
            text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
            background-color: transparent;
        }
    }
}