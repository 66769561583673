.recolectStart {
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  .txt_enter {
    text-align: start;
    width: 300px;
    height: 91px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.5);
    margin-left: 2rem;
  }
  .txt_enter2{
    width: 300px;
    height: 21px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: start;
    margin: 2rem 0 2rem 2rem;
  }
  .radio-label {
    width: 300px;
    height: 13px;    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 1rem auto 1rem 3rem;
    .radio-input {
      visibility: hidden;
    }
    .radio-input:checked + span:after {
      opacity: 1;
    }
    .custom-radio {
      width: 25px;
      height: 25px;
      border: 3px solid rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      position: relative;
      margin-right: 1rem;
    }
    .custom-radio::after {
      content: '';
      width: 11px;
      height: 11px;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.2s;
    }    
  } 
  .entrada{
    box-sizing: border-box;
    width: 246px;
    height: 38px;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    margin: 1rem auto 0 4rem;
    background-color: transparent;
    color: white;
  }
  .entrada::placeholder{
    color: white;
  } 
}