.btn-continue {
    margin-top: 1em;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
  border: 1px solid #000000;
  color: white;
  padding: 8px;
}


.qrflow-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
}

.button-primary {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5) ;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 6px 10px 6px 10px;
  color: white;
  &:hover {
    background: rgba(0,0,0,0.3);
  }
}

.button-primary-color {
  border-radius: 8px;
  background: rgb(0, 62, 170,0.8) ;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 6px 10px 6px 10px;
  width: 100px;
  color: white;
  &:hover {
    background: rgb(0, 62, 170, 0.6);
  }
}

.button-secondary-color {
  border-radius: 8px;
  background: rgb(0, 0, 0,0.8) ;
  border: none;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 6px 10px 6px 10px;
  color: white;
  width: 100px;
  &:hover {
    background: rgb(0, 0, 0,0.6);
  }
}

.input-sms {
  margin-top: 10px;
  border-radius: 8px;
  text-align: start;
  border: 1px solid rgba(0,0,0,0.6);
  height: 40px;
  width: 100%;
  padding: 6px 10px 6px 10px;
  &:focus {
    border: 2px solid rgba(0,0,0,1);
  }

}


