.introcheck {
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: white;
  .txt-line-one {
    width: 160px;
    height: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .imgLogo{
    width: 160px;
    height: 40px;
  }
  .mensajeAcceso{
    width: 180px;
    height: 23px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }
  .contenedorIconos{
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin: 2rem 0;
  }
  .contenedorAceptoTerminos{
    display: flex;
    .checkbox_accepterms{
      width: 20px;
      height: 20px;
      border: 2px solid #FFFFFF;
      border-radius: 6px;
      margin: 0.5rem 0.5rem auto 0;
      box-sizing: border-box;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .checkbox_accepterms:checked::before{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      content: '\2713';
      color: white;
      font-weight: bolder;
    }
    .txt-terminos{
      width: 214px;
      height: 127px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      display: flex;
      align-items: center;
    }
  }
  .linkLeerMas{
    width: 190px;
    .txtLeerMas{
      width: 77px;
      height: 15px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: white;
    }
  }
  .img-icon2{
      width: 14%;
  }
  .container-terms {
     padding: 2em;

    .txt-lines {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
    }
    .txt-read-more {
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      text-decoration: none;
      text-align: right;
      color: white;
    }
    .txt-lines-title {
      text-align: left;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 11px;
    }
  }
}
