.general {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .huella-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: white;
    height: 100% !important;
    width: 100%;
    position: relative;
    align-items: center;
    text-align: center;
    text-align: -webkit-center;
    .text-1 {
      margin-top: 2.8em;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      /* or 109% */

      text-align: center;

      color: #ffffff;
      margin-bottom: 0.5em;
    }
    .img-person {
      margin-top: 40px;
      margin-bottom: 25px;
      width: 72px;
      height: 70px;
    }

    .txt-sub-2 {
      line-height: 18px;
      /* or 109% */
      text-align: center;
      font-weight: 800;
      font-size: 14px;
      color: #ffffff;
    }

    .div-2 {
      margin-top: 2em;
      display: flex;
      flex-direction: column;
      // padding: 1em;
      width: 90%;
      height: auto; 
      .icon-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }
      p {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
      }
      .icon-div {
        display: flex;
        flex-direction: column;
        // width: 95px !important;
        // height: 100px !important;
        align-items: center;
        justify-content: space-between;
        .img-container {
          display: flex;
          border-radius: 50%;
          border: 0.4px dashed #ffffff;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          height: 70px;
          width: 70px;
          margin-bottom: 5px;
          .img {
            // height: 50px;
            // width: 35px;
          }
        }
      }
      .icon-div-2 {
        width: 55%;
        height: 9vh;
        top: 43.5%;
        border-radius: 50%;

        border: 0.4px dashed #ffffff;
        img {
          padding: 0.4em;
        }
      }
    }
    .div-3 {
      margin-top: 45px;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        /* or 120% */
        padding: 0.4em;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}