.contenedorIdentidaDig{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 92vh;
    width: 100%;
    .titulo{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .tituloSeleccion{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .tituloAhora{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .tituloSeleccionaAvatar{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;
    }
}