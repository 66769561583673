.screen-container {
  width: 100vw;
  height: 100vh;
  // background-image: radial-gradient(#047bd6, #000e1a);
 
//  z-index: 10;

  .info-container {
   
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .camera-border {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50% 50% 50% 50% / 42% 42% 58% 58%;
    border-width: 3px;
    border-style: solid;
    width: 85%;
    height: auto;

    .camera-video {
      height: auto;
      max-width: 100%;
      object-fit: contain;
      border-radius: 50% 50% 50% 50% / 42% 42% 58% 58%;
      transform: scaleX(-1);
    }
  }

  .text-top {
    margin-top: -70px;
    margin-bottom: 20px;
    height: 58px;
  }
  .text-bottom {
    margin-top: 20px;
    height: 88px;
    box-shadow: 0 0 0 0px rgb(0, 0, 0);
  }
}
