.contenedorTop{
    display: flex;
    position: fixed;
    top: 1rem;
    right: 1rem;
    align-items: end;
    .speech-bubble {
        position: absolute;
        top: 1rem;
        right: 4rem;
        background: white;
        width: 280px;
        height: 87px;
        border-radius: 8px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
        color: black;
    }        
    .speech-bubble:after {
        content: '';
        position: absolute;
        right: 5%;
        top: 30%;
        width: 0;
        height: 0;
        border: 32px solid transparent;
        border-left-color: white;
        border-right: 0;
        border-top: 0;
        margin-top: -16px;
        margin-right: -32px;
    }
    .contenedorBotones{
        display: flex;
        position: absolute;
        top: 7rem;
        right: 5rem;
        .botonAccion{
            box-sizing: border-box;
            width: 65px;
            height: 33px;
            border: 1px solid #40A7E0;
            border-radius: 8px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #40A8E0;
            background-color: transparent;
            margin-left: 1rem;
        }
    }  
    .botonAtena{
        width: 43px;
        height: 43px;
        border-radius: 50%;
        padding: 0;
        background-color: transparent;
        border: none;
    }
    .ateneaContenedor{
        color: black;
        position: relative;
        left: 1.5%;
        .botonProbarConexion{
            margin-top: 1rem;
            width: 60%;
            height: 30px;
            background: #00BCE2;
            border-radius: 20px;
            color: white;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            border: 2px;
        }
    }
}