@import "../../sass/responsive.scss";
.advices {
  margin-top: 7em;
  h1 {
    line-height: 0.9;
    font-size: 22px;
  }
  .advice-title {
    font-size: 27px;
  }

  .advice1 {
    margin-top: 16%;
    .img1 {
      transform: rotate(10deg);
      width: 123.99px;
      height: 80.39px;
      margin-bottom: 14%;
    }
    margin-bottom: 4em;
  }
}
.advices2 {
  padding: 0% 8% 0% 8%;
  margin-top: 8em;
  .advices2-txt {
   
    margin-top: 3em;
    margin-bottom: 3em;
    h1 {
      line-height: 0.9;
      font-size: 18px;
    }
  }
}
.advices3 {
  margin-top: 4em;
  .advices3-txt {
  
    .only-txt-big{
      h1{
        font-weight: bold;
        font-size:22px;
      }
    }
    h1 {
      font-weight: 600;
      line-height: 0.9;
      font-size: 18px;
    }
    h2 {
      font-weight: 600;
      line-height: 0.9;
      font-size: 18px;
    }
  }
}
.recomendacion{
  height: 80vh;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  .tituloVerificacion{
    width: 212px;
    height: 56px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
  }
  .indicacionDocSup{
    width: 239px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: start;
    margin: 0;
  }
  .indicacionDocInf{
    width: 239px;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: start;
    font-weight: bolder;
    margin-bottom: 2rem;
  }
  .ubicacionDoc{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
  }
  .menajeQueremosVerteSup{
    width: 239px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .menajeQueremosVerteInf{
    width: 239px; 
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .permisoCamaraSup{
    width: 239px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 2rem 0 0 0 ;
  }
  .permisoCamaraInf{
    width: 239px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bolder;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .mensajeQueremos{
    width: 306px;
    height: 80px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .mensajeConsejo{
    width: 259px;
    height: 63px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
  }
}
.slider .slick-dots li button{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.slider .slick-dots li button:before{
  color: transparent;
}
.slider .slick-dots li.slick-active button{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
  background-color: #FFFFFF;
}
.slider .slick-dots li.slick-active button:before{
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.slider .slick-list {
  height: 70vh;
}
.contenedorTerminos{
  height: 92vh;
}